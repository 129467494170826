import React from 'react';
import tw from 'twin.macro';

const NavBar = () => (
  <NavBarContainer>
    <Header>Docker Compose Builder</Header>
  </NavBarContainer>
);

const NavBarContainer = tw.nav`bg-black flex justify-center`;

const Header = tw.h1`text-primary p-1`;

export default NavBar;

import React from 'react';
import tw from 'twin.macro';

const Editor = () => (
  <EditorContainer>
    <h1>Editor</h1>
  </EditorContainer>
);

const EditorContainer = tw.div`text-secondary overflow-auto w-1/6 flex-1 flex justify-center`;

export default Editor;

import React from 'react';
import tw from 'twin.macro';

const ToolBox = () => (
  <ToolBoxContainer>
    <Header>Toolbox</Header>
  </ToolBoxContainer>
);

const ToolBoxContainer = tw.div`bg-lightBlack flex justify-center`;

const Header = tw.h1`text-primary p-1`;

export default ToolBox;

import React from 'react';
import tw from 'twin.macro';

const SideBar = () => (
  <SideBarContainer>
    <h1>Sidebar</h1>
  </SideBarContainer>
);

const SideBarContainer = tw.div`bg-tertiary overflow-auto w-1/10 flex justify-center min-w-sidebar`;

export default SideBar;

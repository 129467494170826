import React from 'react';
import tw from 'twin.macro';

const Palette = () => (
  <PaeletteContainer>
    <h1>Palette</h1>
  </PaeletteContainer>
);

const PaeletteContainer = tw.div`bg-red w-1/10 flex justify-center min-w-sidebar`;

export default Palette;

import React from 'react';
import tw from 'twin.macro';

import Editor from './components/Editor';
import NavBar from './components/NavBar';
import Palette from './components/Palette';
import SideBar from './components/SideBar';
import ToolBox from './components/ToolBox';

const App = () => (
  <MainBody>
    <header>
      <NavBar />
      <ToolBox />
    </header>

    <MainContainer>
      <InnerContainer>
        <Palette />
        <Editor />
        <SideBar />
      </InnerContainer>
    </MainContainer>
  </MainBody>
);

const MainBody = tw.div`h-screen flex flex-col`;

const MainContainer = tw.main`flex-1`;

const InnerContainer = tw.div`h-full flex`;

export default App;
